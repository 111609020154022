import { mergeDeepLeft } from 'ramda';

import { FontSize as FontSizes, FontFamilies, ComponentGroups } from '@powdr/constants';

/** ***************************************** */
/* Default Font Overrides */
/** ***************************************** */
const FontSizeOverrides = {
  // EXAMPLE: reg30: '100px',
  REG40: '18px',
  REG50: '20px',
  MED30: '26px',
};

const FontSize = mergeDeepLeft(FontSizeOverrides, FontSizes);

const ComponentFontAdjustments = {
  [ComponentGroups.WYSIWIG]: {
    h1: '50px',
    h2: '50px',
    h3: '30px',
    h4: '30px',
    h5: '30px',
    h6: '30px',
    'content-large-breakpoint': '18px',
    content: '16px',
    button: '16px',
  },
};

const fonts = {
  [FontFamilies.PRI_HDR_BLK]: 'font-family: "BlockGothicRR-ExtraBoldCond", sans-serif',
  [FontFamilies.SEC_HDR_BLK]: 'font-family: "HCo Gotham Ultra", sans-serif',
  [FontFamilies.PRI_BDY_HVY]: 'font-family: "HCo Gotham Bold", sans-serif',
  [FontFamilies.PRI_BDY_MED]: 'font-family: "HCo Gotham Book", sans-serif',
};

export const fontTheme = {
  fonts,
  FontSize,
  ComponentFontAdjustments,
};
