import { ThemeProps as Prop } from '@powdr/constants';

export const StaticTheme = {
  colors: {
    [Prop.BLACK]: '#000000',
    [Prop.WHITE]: '#FFFFFF',
  },
  shades: {
    [Prop.LIGHTEST]: '#f0f0f0',
    [Prop.LIGHTER]: '#E0E0E0',
    [Prop.LIGHT]: '#A0ADAD',
    [Prop.GRAY]: '#909D9D',
    [Prop.GRAYER]: '#6F7C7C',
    [Prop.GRAYEST]: '#4D585B',
    [Prop.DARK]: '#333333',
    [Prop.DARKER]: '#222222',
    [Prop.DARKEST]: '#292929',
  },
  difficulty: {
    [Prop.BEGINNER]: '#73C053',
    [Prop.INTERMEDIATE]: '#30A8E0',
    [Prop.EXPERT]: '#000000',
  },
  social: {
    [Prop.FACEBOOK]: '#3B5998',
    [Prop.EVENTBRITE]: '#F6682F',
  },
  alerts: {
    [Prop.ACTIVE]: '#2176b8',
    [Prop.SUCCESS]: '#73c053',
    [Prop.INFO]: '#0b24fb',
    [Prop.WARNING]: '#FFB400',
    [Prop.DANGER]: '#FE4A49',
  },

  //
  parkingCal: {
    [Prop.SECTION_BG]: '#f0f0f0',
    [Prop.SECTION_TXT]: '#116C92',
    [Prop.ITEM_BG]: '#DCDCDC',
    [Prop.ITEM_TXT]: '#116C92',
    [Prop.ITEM_TXT_HOVER]: '#00A1E4',
    [Prop.ITEM_BG_NO_INTERACT]: '#ECECEC',
    [Prop.ITEM_TXT_NO_INTERACT]: '#BED4DC',
    [Prop.ITEM_BG_AVAILABLE]: '#00A1E4',
    [Prop.ITEM_TXT_AVAILABLE]: '#FFFFFF',
    [Prop.ITEM_BTN_BG_AVAILABLE]: '#FFD200',
    [Prop.ITEM_BTN_TXT_AVAILABLE]: '#116C92',
    [Prop.ITEM_BG_SELECTED]: '#116C92',
    [Prop.ITEM_TXT_SELECTED]: '#FFFFFF',
    [Prop.ITEM_BTN_BG_SELECTED]: '#00A1E4',
    [Prop.ITEM_BTN_TXT_SELECTED]: '#FFFFFF',
    [Prop.RES_ITEM_CONTAINER_BG]: '#FFFFFF',
  },
  tv: {
    [Prop.BACKGROUND]: '#116C92',
    [Prop.HEADER]: '#FFD200',
    [Prop.CONTENT]: '#FFD200',
    [Prop.BORDER]: '#FFFFFF',
    [Prop.ICON]: '#FFFFFF',
    [Prop.LINK]: '#FFD200',
    [Prop.LINK_HOVER]: '#00A1E4',
    [Prop.ODD_EVEN_OFFSET]: '#E5EDED',
    [Prop.SIDE_NAV_ITEM_BG]: '#00A1E4',
    [Prop.SIDE_NAV_ITEM_TXT]: '#FFD200',
    [Prop.SIDE_NAV_ITEM_ICON]: '#FFD200',
    [Prop.SIDE_NAV_ITEM_BG_ACTIVE]: '#FFD200',
    [Prop.SIDE_NAV_ITEM_TXT_ACTIVE]: '#116C92',
    [Prop.SIDE_NAV_ITEM_ICON_ACTIVE]: '#116C92',
    [Prop.TOP_NAV_ITEM_TXT]: '#FFD200',
    [Prop.ALERT_MARQUEE_BG]: '#333333',
    [Prop.ALERT_MARQUEE_ALERT_TXT]: '#FFFFFF',
    [Prop.ALERT_MARQUEE_TEMP_TIME_TXT]: '#FFFFFF',
    [Prop.ALERT_OVERLAY_BG]: '#004663',
    [Prop.ALERT_OVERLAY_TXT]: '#FFFFFF',
    [Prop.ALERT_OVERLAY_TEMP_TIME_TXT]: '#FFFFFF',
    [Prop.LIFTS_TXT]: '#FFFFFF',
    [Prop.LIFTS_ICON]: '#FFFFFF',
    [Prop.EVENT_1]: '#FFD200',
    [Prop.EVENT_2]: '#FFD200',
  },
};
