module.exports = {
  // TODO: Figure out which fields are actually needed here then remove the rest from all properties
  gatsbyConfig: {
    property: 'mtbachelor',
    siteName: 'Mtbachelor Mountain Resort',
    siteUrl: 'https://www.mtbachelor.com/',
    reCaptchaDBPropertyName: 'MtBachelor',
    reCaptchaPublicKey: '6LfiiYIUAAAAAEvSxGg7A_ZOQRemWMgGBoTr0Z5P',
    color: '#64CAC7',
    facebook: '',
    instagram: '',
    twitter: '',
    dor: {
      temperature: {
        name: 'snowplot',
        api: 'sensors',
      },
      liveWeather: true,
      weatherApi: 'openweathermap',
    },
    robots: [],
    metaImageFallbackUrl: '',
    dynamicTypes: [
      'pressRelease',
      'event',
      'blog',
    ],
    ignore404Routes: [
      '/things-to-do/events/view-all-events',
      '/culture/outplay-culture/blog',
      '/plan-your-trip/nordic/events',
    ],
  },
  // TODO: Good way of managing API keys per site, maybe use for formbucket and mailchimp?
  contentfulConfig: {
    spaceId: '38vc2unmqsq5',
    accessToken: 'D2b0ZG2uKbPvWdW38GS0y3_hQN9AxH-iJnKijjoI0cA',
  },
};
